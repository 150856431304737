import React from 'react'
import s from './ChatMessage.module.scss'

// Components
import ChatBubble from 'components/site/ChatBubble/ChatBubble'
import ImageCard from 'components/site/ImageCard/ImageCard'
import ImageCardGroup from 'components/site/ImageCardGroup/ImageCardGroup'
import ImageCardCarousel from 'components/site/ImageCardCarousel/ImageCardCarousel'
import ChatButtonGroup from 'components/site/ChatButtonGroup/ChatButtonGroup'
import ChatMap from 'components/site/ChatMap/ChatMap'
import AppStoreCard from 'components/site/AppStoreCard/AppStoreCard'
import SaveClientValue from 'components/site/SaveClientValue/SaveClientValue'

// Assets
import { ReactComponent as BotAvatar } from 'media/bot-avatar.svg'

// Defs
const COMPONENT_MAP = {
	message: ChatBubble,
	imageCard: ImageCard,
	buttonGroup: ChatButtonGroup,
	map: ChatMap,
	imageCardGroup: ImageCardGroup,
	imageCardCarousel: ImageCardCarousel,
	appStoreCard: AppStoreCard,
	saveClientValue: SaveClientValue
}

//
export default function ChatMessage(props) {
	const { id, responseType, from, data } = props
	const Component = COMPONENT_MAP[responseType]

	// @todo: proper error handling
	if (!Component) return <div>Unsupported card.</div>

	return (
		<div className={s.root} data-from={from} data-type={responseType}>
			<div className={s.inner}>
				{(from === 'bot' || from === 'human') && <BotAvatar className={s.botAvatar} />}
				<Component {...data} className={s.component} messageId={id} />
			</div>
		</div>
	)
}
