import React, { useContext, useEffect, useState } from 'react'
import { firestore } from 'config/firebase'
import AppCtx from 'contexts/App'
import s from './ProfileEditor.module.scss'

// Components
import FormItem from 'components/generic/form/FormItem/FormItem'
import Button from 'components/generic/Button/Button'

//
export default function ProfileEditor() {
	const {
		content: { profile: content },
		profileData,
	} = useContext(AppCtx)

	const [alertMessage, setAlertMessage] = useState('')
	const [profileEditorForm, setProfileEditorForm] = useState({
		name: profileData?.name || '',
		email: profileData?.email || '',
		licensePlate: profileData?.carDetails?.licensePlate || '',
	})

	useEffect(() => {
		setProfileEditorForm({
			name: profileData?.name || '',
			email: profileData?.email || '',
			licensePlate: profileData?.carDetails?.licensePlate || '',
		})
	}, [profileData])

	const onSubmit = async (event) => {
		event.preventDefault()

		try {
			await firestore.doc(`users/${profileData.id}`).set(
				{
					name: profileEditorForm.name,
					email: profileEditorForm.email,
					carDetails: { licensePlate: prepLicensePlate(profileEditorForm.licensePlate) },
				},
				{
					merge: true,
				},
			)
			setAlertMessage(content.editorSavedAlertMessage)
		} catch (e) {
			// @TODO: add error when firebase insert goes wrong.
		}
	}

	const prepLicensePlate = (licensePlate) => {
		const cleanplate = licensePlate.split('-').join('')

		return cleanplate.toLowerCase()
	}

	const onFormChange = (event) => {
		const { name, value } = event.target
		setProfileEditorForm({ ...profileEditorForm, [name]: value })
		setAlertMessage('')
	}

	return (
		<form className={s.root} data-gap="m" onSubmit={onSubmit}>
			<FormItem name="name" type="text" label={content.nameLabel} value={profileEditorForm.name} onChange={onFormChange} />
			<FormItem name="email" type="email" label={content.emailLabel} value={profileEditorForm.email} onChange={onFormChange} required />
			<FormItem name="licensePlate" type="text" label={content.licensePlateLabel} value={profileEditorForm.licensePlate} onChange={onFormChange} />
			<div>
				<div className={s.controls} data-gap="xxs">
					<Button type="submit" kind="sinbad">
						{content.editorSaveLabel}
					</Button>
					<p role="alert" className={s.alertMessage}>
						{alertMessage}
					</p>
				</div>
			</div>
		</form>
	)
}
