import React from 'react'
import { For } from 'react-loops'
import s from './ImageCard.module.scss'

// Components
import ChatButtonGroup from 'components/site/ChatButtonGroup/ChatButtonGroup'
import Tag from 'components/generic/Tag/Tag'

//
export default function ImageCard({ messageId, title, imageUrl, tags, buttonGroup, showButtons = true, showTags = true, disabled = false, className = '' }) {
	return (
		<article className={`${s.root} ${className}`} data-disabled={disabled}>
			{imageUrl && <img className={s.image} src={imageUrl} alt="" />}
			<p className={s.name}>{title}</p>
			{showTags && (
				<p className={s.tags} data-gap="xxxs">
					<For of={tags} as={(tag) => <Tag value={tag} />} />
				</p>
			)}
			{showButtons && (
				<div className={s.buttons}>
					<ChatButtonGroup {...buttonGroup} messageId={messageId} />
				</div>
			)}
		</article>
	)
}
