import { firestore } from 'config/firebase'

// Hooks
import { useDocument } from 'react-firebase-hooks/firestore'

/**
 * Handle fetching profile info
 */
export default (user) => {
	let query = null

	if (user) {
		query = firestore.doc(`users/${user.uid}`)
	}

	const [profile, loading, error] = useDocument(query, {
		idField: 'id',
	})

	if (error) {
		console.error(error)
	}

	return { ...profile?.data(), ...{ id: profile?.id } }
}
