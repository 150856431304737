import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import AppCtx from 'contexts/App'

// Components
import OnboardingFlow from 'components/site/Onboarding/Flow/Flow'

// Hooks
import useBrowserTitle from 'utils/useBrowserTitle'

//
export default function Onboarding() {
	const { content } = useContext(AppCtx)

	return (
		<div data-page="Onboarding">
			<Helmet>
				<title>{useBrowserTitle(content.general.loginBrowserTitle)}</title>
			</Helmet>
			<h1 className="visually-hidden">{content.general.loginTitle}</h1>
			<OnboardingFlow />
		</div>
	)
}
