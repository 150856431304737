import React, { useContext } from 'react'
import AppCtx from 'contexts/App'
import s from './TypingIndicator.module.scss'

//
export default function TypingIndicator() {
	const {
		content: {
			general: { typingIndicatorLabel },
		},
	} = useContext(AppCtx)

	return (
		<div className={s.root}>
			<p className="visually-hidden">{typingIndicatorLabel}</p>
			<div className={s.dot} />
			<div className={s.dot} />
			<div className={s.dot} />
		</div>
	)
}
