import React, { useContext, useState } from 'react'
import AppCtx from 'contexts/App'
import OnboardingCtx from 'contexts/Onboarding'
import s from './Email.module.scss'

// Components
import OnboardingLayout from 'components/layouts/Onboarding/Onboarding'
import FormItem from 'components/generic/form/FormItem/FormItem'
import Button from 'components/generic/Button/Button'
import Overlay from 'components/generic/Overlay/Overlay'
import MarkdownDefault from 'components/generic/Markdown/Default/Default'

// Assets
import terms from 'markdown/terms.md'

//
export default function Email() {
	const {
		content: {
			general: generalContent,
			onboarding: { email: content },
		},
	} = useContext(AppCtx)

	const { formData, onFormChange } = useContext(OnboardingCtx)

	const [conditionsOverlayIsOpen, setConditionsOverlayIsOpen] = useState(false)

	const onConditionsLinkClick = (event) => {
		event.preventDefault()
		setConditionsOverlayIsOpen(true)
	}

	const contentComponent = (
		<div className={s.content}>
			<h2>{content.title}</h2>
			<p>
				{content.body}{' '}
				<a href="#?" role="button" onClick={onConditionsLinkClick}>
					{content.conditionsLabel}
				</a>
			</p>
			<FormItem name="email" type="email" value={formData.email} onChange={onFormChange} required />
			<Overlay isOpen={conditionsOverlayIsOpen} onClose={() => setConditionsOverlayIsOpen(false)} lockViewport={false}>
				<MarkdownDefault>{terms}</MarkdownDefault>
			</Overlay>
		</div>
	)

	const footerComponent = (
		<React.Fragment>
			<Button kind="black" type="reset">
				{generalContent.prevLabel}
			</Button>
			<Button kind="black" type="submit">
				{generalContent.nextLabel}
			</Button>
		</React.Fragment>
	)

	return <OnboardingLayout content={contentComponent} footer={footerComponent} />
}
