/**
 * Detect (mobile) client platform; https://stackoverflow.com/a/21742107
 * @returns {String} - Platform identifier
 */
export default () => {
	const ua = navigator.userAgent

	// Windows Phone must come first because its UA also contains "Android" :/
	if (/windows phone/i.test(ua)) {
		return 'WindowsPhone'
	}

	if (/android/i.test(ua)) {
		return 'Android'
	}

	// iOS detection from: http://stackoverflow.com/a/9039885/177710
	if (/iPad|iPhone|iPod/.test(ua) && !window.MSStream) {
		return 'iOS'
	}

	return 'unknown'
}
