// Components
import HomePage from 'components/pages/Home'
import OnboardingPage from 'components/pages/Onboarding'
import LoginPage from 'components/pages/Login'
import NotFoundPage from 'components/pages/NotFound'

//
export default [
	{
		id: 'home',
		path: '/',
		exact: true,
		Component: HomePage,
	},
	{
		id: 'chat',
		path: '/chat/:flow?',
		Component: HomePage,
	},
	{
		id: 'onboarding',
		path: '/onboarding',
		Component: OnboardingPage,
	},
	{
		id: 'login',
		path: '/login',
		Component: LoginPage,
	},
	{
		id: 'not-found',
		Component: NotFoundPage,
	},
]
