// Mock assets
import imageUrl from 'media/mock/food1.png'

//
export default {
	title: 'Slagerij Vleeschmeester',
	imageUrl,
	tags: ['Burger', 'Familie'],
	buttonGroup: {
		type: 'imageCard',
		buttons: [
			{
				label: 'i',
				action: {
					type: 'actionResponse',
					payload: {
						type: 'info',
						locationKey: 'locationKey',
					},
				},
				kind: 'royal-blue',
			},
			{
				label: 'Locatie',
				action: {
					type: 'actionResponse',
					payload: {
						type: 'map',
						locationKey: 'locationKey',
					},
				},
				kind: 'royal-blue',
			},
			{
				label: 'Menu',
				action: {
					type: 'link',
					value: 'https://www.vleeschmeester.nl/vleesch',
				},
				kind: 'royal-blue',
			},
		],
	},
}
