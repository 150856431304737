/**
 * @module utils/useFetch
 */

import useFetch from 'fetch-suspense'

// Data
import content from 'mock-api/content/nl/GET.json'

/**
 *
 */
export const useFetchContent = (language, fetchOptions) => {
	// const uri = `${process.env.REACT_APP_CONTENT_API_BASE_URI}/${language}`
	// return _fetch(uri, fetchOptions)
	return [content.data, null]
}

/**
 * Wrapper around `useFetch`
 * @param {String} uri - API endpoint
 * @param {Object} fetchOptions - `fetch` options
 */
// eslint-disable-next-line
const _fetch = (uri, fetchOptions = {}) => {
	try {
		const result = useFetch(uri, fetchOptions, { metadata: true })
		const {
			ok,
			response: { success, data },
		} = result
		const error = !(ok && success) && 'API response not OK'

		return [data, error]
	} catch (e) {
		if (e instanceof Promise) throw e
		else return [null, e]
	}
}
