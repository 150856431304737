import React, { useContext, useEffect, useState } from 'react'
import firebase, { firestore } from 'config/firebase'
import AppCtx from 'contexts/App'

import FormItem from 'components/generic/form/FormItem/FormItem'
import Button from 'components/generic/Button/Button'

import s from './Login.module.scss'
import { Link } from 'react-router-dom'
import history from 'utils/history'
//
export default function LoginView() {
	const {
		content: { login: content },
		user
	} = useContext(AppCtx)

	const [email, setEmail] = useState()
	const [message, setMessage] = useState({
		type: '',
		message: '',
	})

	useEffect(() => {
		const emailFromStorage = window.localStorage.getItem('email') || window.localStorage.getItem('email')

		handleSignIn(emailFromStorage)
	}, [])

	const login = () => {
		// When submitting Send the signin link
		const actionCodeSettings = {
			url: window.location.href,
			handleCodeInApp: true,
			// iOS: {
			// 	bundleId: process.env.REACT_APP_BUNDLE_ID,
			// },
			// android: {
			// 	packageName: process.env.REACT_APP_BUNDLE_ID,
			// 	installApp: true,
			// },
		}

		firebase
			.auth()
			.sendSignInLinkToEmail(email, actionCodeSettings)
			.then(() => {
				window.localStorage.setItem('email', email)

				setEmail('')

				setMessage({
					type: 'success',
					message: `We hebben een mail verzonden naar ${email}. Klik op de link in de mailbox om in te loggen`,
				})
			})
			.catch((error) => {
				// Handle Errors here.
				const errorMessage = error.message

				setMessage({
					type: 'error',
					message: `Helaas! Er is iets fout gegaan. Error: "${errorMessage}"`,
				})
			})
	}

	const handleChange = (e) => {
		setEmail(e.target.value)
	}

	/**
	 * Handles automatically signing-in the app if we clicked on the sign-in link in the email.
	 */
	const handleSignIn = async (email) => {
		if(user && !user.isAnonymous) {
			history.push('/')
		}

		if(!email) return;

		if (window.location.href.includes('upgradeAccount')) {
			try {
				const credentials = firebase.auth.EmailAuthProvider.credentialWithLink(email, window.location.href)
				const usercred = await firebase.auth().currentUser.linkWithCredential(credentials)

				const user = usercred.user;

				console.log("Anonymous account successfully upgraded", user);

				// Save profile data
				await firestore.doc(`users/${user.uid}`).set(
					{
						email,
						memberPrompt: 'no',
					},
					{ merge: true },
				)

				history.push('/')

			} catch(e) {
				console.log("Error upgrading anonymous account", e);

				setMessage({
					type: 'error',
					message: `Helaas! Er is iets fout gegaan. Error: "${e.message}"`,
				})
			}
		}
		else {
			if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
				if (email) {
					firebase
						.auth()
						.signInWithEmailLink(email, window.location.href)
						.then(async (result) => {
							try {
								// Clear email from storage.
								window.localStorage.removeItem('email')

								// Signed-in user's information.
								var user = result.user
								var isNewUser = result.additionalUserInfo.isNewUser

								// Save profile data
								await firestore.doc(`users/${user.uid}`).set(
									{
										email,
										memberPrompt: 'no',
									},
									{ merge: true },
								)

								history.push('/')
							} catch (e) {
								window.location.href = '/'
							}
						})
						.catch(function(error) {
							setMessage({
								type: 'error',
								message: `Helaas! Er is iets fout gegaan. Error: "${error.message}"`,
							})
						})
				}
			}
		}


	}

	return (
		<div className={s.root}>
			<h1>{content.title}</h1>

			<p>
				{content.subtitle}
				{/* {content.backLabel} <Link to="/onboarding"> {content.onboarding} </Link> */}
			</p>

			<div className={s.bottom}>
				<h3>{content.loginIntro}</h3>

				<p>{message.message}</p>
				<div className={s.content}>
					<FormItem className={s.email} name="email" type="email" value={email} onChange={handleChange} required />
				</div>

				<div className={s.content}>
					<Button onClick={login} kind="black" className={s.button}>
						{content.loginLabel}
					</Button>
				</div>
			</div>
		</div>
	)
}
