/**
 * Initialize native scanner bridge
 */
export default (sendAction) => {
	// Native QR scanner receiver
	window.triggerFlow = async (flow, message) => {
		if (flow === 'checkinFood') {
			sendAction({
				type: 'messageResponse',
				payload: {
					type: 'messageResponse',
					message: `Check in bij ${message}`,
					visible: false,
				},
			})

			return
		}

		if (flow === 'checkinProduct') {
			sendAction({
				type: 'messageResponse',
				payload: {
					type: 'messageResponse',
					message: `Product check in met ${message}`,
					visible: false,
				},
			})

			return
		}

		console.error(`Trigger flow called with undefined flow? flow: ${flow}`)
	}
}
