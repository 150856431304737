import firebase from '@firebase/app'

import 'firebase/firestore'
import 'firebase/analytics'
import 'firebase/auth'

firebase.initializeApp({
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
})

const firestore = firebase.firestore()

if (process.env.REACT_APP_ENV === 'DEVELOPMENT') {
	console.log('testing locally -- hitting local functions and firestore emulators')

	firebase.auth().useEmulator('http://localhost:9099/')

	firestore.settings({
		host: 'localhost:8080',
		ssl: false,
	})
}

const usersRef = firestore.collection('users')

const getMessageRef = (userId) => {
	if (!userId) {
		throw new Error('You need to pass a userId')
	}

	return firestore
		.collection(`users`)
		.doc(userId)
		.collection('messages')
}

export { firestore, getMessageRef, usersRef }

export default firebase
