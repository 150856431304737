import React from 'react'
import s from './SplashScreen.module.scss'

// Components
import { ReactComponent as Wheel1 } from 'media/wheel-1.svg'
import { ReactComponent as Wheel2 } from 'media/wheel-2.svg'
import { ReactComponent as Wheel3 } from 'media/wheel-3.svg'

// Defs
const LABEL_MAP = {
	en: 'Loading…',
	nl: 'Laden…',
	de: 'Laden…',
	fr: 'Chargement en cours…',
	es: 'Cargando…',
}

const ERROR_LABEL_MAP = {
	en: 'Loading error…',
	nl: 'Laadfout…',
	de: 'Error…',
	fr: 'Error…',
	es: 'Error…',
}

//
export default function SplashScreen({ language, didError }) {
	const title = didError ? ERROR_LABEL_MAP[language] : LABEL_MAP[language]

	return (
		<article className={s.root}>
			<div className={s.main}>
				<h1>{title}</h1>
			</div>
			<div>
				<div className={s.wheels} role="presentation">
					<Wheel1 className={`${s.wheel} ${s.wheel1}`} />
					<Wheel2 className={`${s.wheel} ${s.wheel2}`} />
					<Wheel3 className={`${s.wheel} ${s.wheel3}`} />
				</div>
			</div>
		</article>
	)
}
