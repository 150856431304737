import firebase from 'config/firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import { isInChatView } from 'utils/isInChatView'

firebase.auth().onAuthStateChanged(function(user) {
	// if (!isInChatView()) {
	// 	return
	// }

	if (user) {
		console.log('Hi ', user?.email, " You're logged in.")
	} else {
		// Always sign in the user when the user is logged out
		// This will create a new anonymous account
		firebase
			.auth()
			.signInAnonymously()
			.then((user) => {
				console.log('Created anonymous account for user')
			})
			.catch((error) => {
				const errorCode = error.code
				const errorMessage = error.message

				console.error(errorCode, errorMessage)
			})
	}
})

/**
 * Handle the auth logic
 */
export default () => {
	const [user, loading, error] = useAuthState(firebase.auth())

	if (error) {
		console.error(error)
	}

	return { user, loading }
}
