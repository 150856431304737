import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import AppCtx from 'contexts/App'
import s from './SiteHeader.module.scss'

// Components
import Button from 'components/generic/Button/Button'

// Assets
import { ReactComponent as BotAvatar } from 'media/bot-avatar.svg'
import { ReactComponent as Logotype } from 'media/logotype.svg'

/**
 * The global site header
 */
export default function SiteHeader() {
	const { appMode, setAppMode } = useContext(AppCtx)

	const onAppModeToggle = (event) => setAppMode(appMode === 'web' ? 'app' : 'web')

	return (
		<header className={s.root}>
			<div className={s.botAvatar}>
				<BotAvatar />
			</div>
			{process.env.REACT_APP_SHOW_APPMODE_CONFIG === 'true' && (
				<Button kind="plain" onClick={onAppModeToggle}>
					Mode: {appMode}
				</Button>
			)}
			<Link to="/" className={s.logo}>
				<Logotype />
			</Link>
		</header>
	)
}
