import React, { useContext } from 'react'
import AppCtx from 'contexts/App'
import s from './Welcome.module.scss'

// Components
import OnboardingLayout from 'components/layouts/Onboarding/Onboarding'
import Button from 'components/generic/Button/Button'

// Assets
import { ReactComponent as Logo } from 'media/logo.svg'

//
export default function Welcome({ infoMessage }) {
	const {
		content: {
			onboarding: { welcome: content },
		},
	} = useContext(AppCtx)

	const headerComponent = (
		<header>
			<h2>{content.title}</h2>
		</header>
	)

	const contentComponent = (
		<div className={s.content}>
			<h3>{content.subtitle}</h3>
		</div>
	)

	const footerComponent = (
		<>
			<Button element="NavLink" href="/login">
				{content.loginLabel}
			</Button>
			{/* <Button kind="black" type="submit">
				{content.startLabel}
			</Button> */}
		</>
	)

	const avatarComponent = <Logo />

	return (
		<>
			{infoMessage.type && (
				<div className={s.alert}>
					{infoMessage.type === 'error' && <p>{infoMessage?.message}</p>}
					{infoMessage.type === 'success' && <p>{infoMessage?.message}</p>}
				</div>
			)}
			<OnboardingLayout header={headerComponent} content={contentComponent} footer={footerComponent} avatar={avatarComponent} />
		</>
	)
}
