// Defs
const CHAT_FLOW_MAP = {
	food: 'Ik wil iets eten',
	drink: 'Ik wil iets drinken',
	product: 'Ik zoek een product',
	parking: 'Ik wil korting met parkeren',
}

/**
 * Initialize native scanner bridge
 */
export default (sendChatMessage) => {
	// Native QR scanner receiver
	window.setresult_scanqr = (value) => {
		try {
			const url = new URL(value)
			const [route, ...args] = url.pathname.split('/').filter((x) => x)

			if (route === 'chat') {
				const flow = args[0]
				if (!flow) return
				sendChatMessage(CHAT_FLOW_MAP[flow])
			}
		} catch (e) {}
	}
}
