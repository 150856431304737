// Polyfills
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'url-polyfill'
import 'focus-visible'
import 'inert-polyfill'
import cssVars from 'css-vars-ponyfill'
import smoothscroll from 'smoothscroll-polyfill'

//
import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from 'components/containers/App/App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

// Init ponyfills
smoothscroll.polyfill()
cssVars({
	watch: true,
})

// Element.matches polyfill
if (!Element.prototype.matches) Element.prototype.matches = Element.prototype.msMatchesSelector

//
ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// Push debug
;(async () => {
	// const swReg = await navigator.serviceWorker.ready
	// const pushSub = await swReg.pushManager.getSubscription()
	// console.info(pushSub || '(No push subscription found)')
})()
