import Markdown from 'markdown-to-jsx'
import s from './ChatBubble.module.scss'

// Config
import md2jsxOptions from 'config/md2jsx'

//
export default function MarkdownDefault({ children }) {
	return <Markdown options={md2jsxOptions}>{children}</Markdown>
}

const Image = ({ src, alt, children, ...props }) => {
	return (
		<a target="_blank" href={src} rel="noopener noreferrer nofollow" className={s.imageLink}>
			<img src={src} alt={alt} {...props} />
		</a>
	)
}

md2jsxOptions.overrides.img = Image
