export default {
	type: 'entry',
	buttons: [
		{
			label: 'Eten',
			kind: 'royal-blue',
			action: {
				type: 'messageResponse',
				payload: {
					type: 'message',
					message: 'Ik wil wel wat eten!',
				},
			},
		},
		{
			label: 'Drinken',
			kind: 'apricot-peach',
			action: {
				type: 'messageResponse',
				payload: {
					type: 'message',
					message: 'Ik wil wel wat drinken!',
				},
			},
		},
		{
			label: 'Product zoeken',
			kind: 'carnation',
			action: {
				type: 'messageResponse',
				payload: {
					type: 'message',
					message: 'Ik zoek een product',
				},
			},
		},
	],
}
