import React from 'react'
import { defaultLanguage } from 'config/settings.json'

// Mock data
import content from 'mock-api/content/nl/GET.json'
import conversationItems from 'mock-data/conversationItems'

//
export default React.createContext({
	appMode: 'web',
	content: content.data,
	language: defaultLanguage,
	conversationItems,
	profileData: {},
	setConversationItems: () => {},
	sendChatMessage: (message) => conversationItems.push({ from: 'user', responseType: 'message', data: { from: 'user', text: message } }),
})
