import React, { useContext } from 'react'
import AppCtx from 'contexts/App'

// Components
import Error from 'components/site/Error/Error'

//
export default function ErrorPage() {
	const {
		content: {
			general: { notFoundErrorTitle, notFoundErrorBody, backToHomeLabel },
		},
	} = useContext(AppCtx)

	return (
		<div data-restrain="max">
			<Error title={notFoundErrorTitle} body={notFoundErrorBody} buttonLabel={backToHomeLabel} />
		</div>
	)
}
