import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import history from 'utils/history'
import AppCtx from 'contexts/App'
import LoginView from 'components/site/Login/Login'
// Hooks
import useBrowserTitle from 'utils/useBrowserTitle'

export default function Login() {
	const { content } = useContext(AppCtx)

	return (
		<div data-page="Login">
			<Helmet>
				<title>{useBrowserTitle(content.general.loginBrowserTitle)}</title>
			</Helmet>
			<h1 className="visually-hidden">{content.general.loginTitle}</h1>
			<LoginView />
		</div>
	)
}
