import React from 'react'
import { For } from 'react-loops'
import s from './ImageCardGroup.module.scss'

// Components
import ImageCard from '../ImageCard/ImageCard'

//
export default function ImageCardGroup({ messageId, cards, className = '' }) {
	return (
		<div className={`${s.root} ${className}`}>
			<For of={cards} as={(card) => <ImageCard {...card} showButtons={false} showTags={false} messageId={messageId} />} />
		</div>
	)
}
