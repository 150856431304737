import React from 'react'
import s from './ImageCardCarousel.module.scss'

// Components
import Carousel from 'components/generic/Carousel/Carousel'
import ImageCard from 'components/site/ImageCard/ImageCard'

//
export default function ImageCardCarousel({ messageId, persistentSelection, selectedIndex, cards, className = '' }) {
	return (
		<div className={`${s.root} ${className}`}>
			<Carousel className={s.carousel} startIndex={selectedIndex} data-has-selection={selectedIndex != null}>
				{cards.map((card, index) => {
					const disabled = persistentSelection && selectedIndex != null && selectedIndex !== index
					return <ImageCard key={index} {...card} className={s.card} messageId={messageId} disabled={disabled} />
				})}
			</Carousel>
		</div>
	)
}
