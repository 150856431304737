import React from 'react'
import s from './ChatMap.module.scss'

//
export default function ChatMap({ location, title, className = '' }) {
	const url = `https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_API_KEY}&q=${encodeURIComponent(location)}`

	return (
		<div className={`${s.root} ${className}`}>
			<iframe className={s.iframe} title={title} src={url} allowFullScreen></iframe>
		</div>
	)
}
