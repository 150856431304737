import React, { useState, useContext, useEffect, useRef } from 'react'
import { For } from 'react-loops'
import firebase, { firestore } from 'config/firebase'
import history from 'utils/history'
import OnboardingCtx from 'contexts/Onboarding'
import AppCtx from 'contexts/App'

import s from './Flow.module.scss'
// Components
import WelcomeStep from 'components/site/Onboarding/Welcome/Welcome'
import ParkingStep from 'components/site/Onboarding/Parking/Parking'
import LicensePlateStep from 'components/site/Onboarding/LicensePlate/LicensePlate'
import EmailStep from 'components/site/Onboarding/Email/Email'
import NameStep from 'components/site/Onboarding/Name/Name'

//
export default function OnboardingFlow({ startStep = 0 }) {
	const { user } = useContext(AppCtx)
	const $root = useRef()
	const [currentStep, setCurrentStep] = useState(startStep)
	const [formData, setFormData] = useState(useContext(OnboardingCtx).formData)
	const steps = [WelcomeStep, ParkingStep, LicensePlateStep, EmailStep, NameStep]
	const [message, setMessage] = useState({ type: '', message: '' })

	const stepConstraints = new Map([
		[LicensePlateStep, () => formData.enableParking === 'no'],
		//
	])

	const onFormChange = ({ target: { name, value } }) => {
		setFormData({ ...formData, [name]: value })
	}

	const onSubmit = (event) => {
		event.preventDefault()
		next()
	}

	const onReset = (event) => {
		event.preventDefault()
		prev()
	}

	const next = () => {
		if (currentStep < steps.length - 1) {
			setCurrentStep(getNextStep())
		} else {
			submit()
		}
	}

	const prev = () => {
		if (currentStep > 0) {
			setCurrentStep(getPreviousStep())
		}
	}

	const getNextStep = () => {
		// Find the next eligible step to render
		let nextStep = currentStep + 1
		for (; nextStep < steps.length; nextStep++) {
			const stepContraint = stepConstraints.get(steps[nextStep])
			if (!stepContraint || !stepContraint()) break
		}
		return nextStep
	}

	const getPreviousStep = () => {
		// Find the previous eligible step to render
		let nextStep = currentStep - 1
		for (; nextStep >= 0; nextStep--) {
			const stepContraint = stepConstraints.get(steps[nextStep])
			if (!stepContraint || !stepContraint()) break
		}
		return nextStep
	}

	const submit = async () => {
		if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
			await upgradeAccount(formData)
		} else {
			// When submitting Send the signin link
			const actionCodeSettings = {
				url: process.env.REACT_APP_EMAIL_REDIRECT,
				handleCodeInApp: true,
				iOS: {
					bundleId: process.env.REACT_APP_BUNDLE_ID,
				},
				android: {
					packageName: process.env.REACT_APP_BUNDLE_ID,
					installApp: true,
				},
			}

			firebase
				.auth()
				.sendSignInLinkToEmail(formData.email, actionCodeSettings)
				.then((e) => {
					window.localStorage.setItem('email', formData.email)
					window.localStorage.setItem('licensePlate', formData.licensePlate)
					window.localStorage.setItem('name', formData.name)

					// Empty the form
					setFormData({ name: '', email: '', licensePlate: '' })

					// Send submit message
					setMessage({
						type: 'success',
						message: `We hebben een mail verzonden naar ${formData.email}. Klik op de link in de mailbox om in te loggen`,
					})
				})
				.catch((error) => {
					const errorMessage = error.message

					setMessage({
						type: 'error',
						message: `Helaas! Er is iets fout gegaan. Error: "${errorMessage}"`,
					})
				})
		}
	}

	const upgradeAccount = async (formData) => {
		// Link the credential to the current user.
		try {
			// const credential = firebase.auth.EmailAuthProvider.credentialWithLink(formData.email, window.location.href)

			// Link account
			// const usercred = await firebase.auth().currentUser.linkWithCredential(credential)
			const usercred = await firebase.auth().signInWithEmailLink(formData.email, window.location.href)

			window.localStorage.removeItem('email')
			window.localStorage.removeItem('licensePlate')
			window.localStorage.removeItem('name')
			// Save profile data
			await firestore.doc(`users/${usercred.user.uid}`).set(
				{
					name: formData.name,
					email: formData.email,
					carDetails: { licensePlate: formData.licensePlate },
					memberPrompt: 'no',
				},
				{ merge: true },
			)

			history.push('/')
		} catch (e) {
			console.log(e)
			setMessage({
				type: 'error',
				message: `Helaas! Er is iets fout gegaan. Error: "${e.message}"`,
			})
		}
	}

	const onboardingCtxValue = { formData, setFormData, onFormChange }

	useEffect(() => {
		const formData = {
			name: window.localStorage.getItem('name'),
			email: window.localStorage.getItem('email'),
			licensePlate: window.localStorage.getItem('licensePlate'),
		}

		if (firebase.auth().isSignInWithEmailLink(window.location.href) && formData.email) {
			upgradeAccount(formData)
		}

		if (user.emailVerified) {
			history.push('/')
		}
	}, [])

	// Step navigation through `scrollIntoView`
	useEffect(() => {
		const $step = [...$root.current?.children][currentStep]
		const scrollBehavior = { behavior: 'instant' }

		if ('scrollBehavior' in document.documentElement.style) {
			scrollBehavior.behavior = 'smooth'
		}

		$step && setTimeout(() => $step.scrollIntoView(scrollBehavior), 500)
	}, [currentStep])

	return (
		<OnboardingCtx.Provider value={onboardingCtxValue}>
			<div ref={$root} className={s.root}>
				<For
					of={steps}
					as={(Step, { index }) => {
						const isActive = index === currentStep

						return (
							<form className={s.stepContainer} inert={!isActive ? 'inert' : undefined} aria-hidden={!isActive} onSubmit={onSubmit} onReset={onReset}>
								<Step isActive={isActive} next={next} prev={prev} infoMessage={message} />
							</form>
						)
					}}
				/>
			</div>
		</OnboardingCtx.Provider>
	)
}
