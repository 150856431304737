import React, { Suspense, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useFetchContent } from 'utils/fetch'
import getSendChatMessage from 'utils/getSendChatMessage'
import getSendAction from 'utils/getSendAction'
import AppCtx from 'contexts/App'
import getGlobalParam from 'utils/getGlobalParam'
import getClientPlatform from 'utils/getClientPlatform'
import history from 'utils/history'
import initScannerBridge from 'utils/initScannerBridge'
import initGetAppTokenBridge from 'utils/initGetAppTokenBridge'
import initFlowTrigger from 'utils/initFlowTrigger'

// Components
import App from 'components/site/App/App'
import SplashScreen from 'components/site/SplashScreen/SplashScreen'
import ErrorBoundary from 'components/containers/ErrorBoundary/ErrorBoundary'

// Hooks
import useAuth from 'hooks/useAuth'
import useConversation from 'hooks/useConversation'
import useProfileData from 'hooks/useProfileData'

// Config
import routes from 'config/routes'
import { defaultLanguage, defaultAppMode } from 'config/settings.json'

const language = getGlobalParam('lang') || defaultLanguage

//
export default function AppContainer() {
	const { user, loading } = useAuth()

	// if (isInChatView() && (loading || !user)) {
	if (loading || !user) {
		return <SplashScreenWrapper />
	}

	return (
		<ErrorBoundary isBootstrap={true}>
			<Suspense fallback={<SplashScreenWrapper />}>
				<AppContainerInner />
			</Suspense>
		</ErrorBoundary>
	)
}

const AppContainerInner = () => {
	const [permissions, setPermissions] = useState({
		notifications: window.Notification?.permission,
	})

	const [content, contentError] = useFetchContent(language)
	if (contentError) throw new Error(contentError)

	const { user } = useAuth()
	const [appMode, setAppMode] = useState(getGlobalParam('appMode', false, null) || defaultAppMode)
	const [conversationItems, setConversationItems] = useState([])
	const [profileData, setProfileData] = useState({})
	const sendChatMessage = getSendChatMessage(user, appMode)
	const sendAction = getSendAction(user, appMode)
	const _conversationItems = useConversation(user)
	const _profile = useProfileData(user)

	const appContext = {
		language,
		content,
		permissions,
		setPermissions,
		user,
		profileData,
		conversationItems,
		setConversationItems,
		sendChatMessage,
		sendAction,
		appMode,
		setAppMode,
		platform: getClientPlatform(),
	}

	initScannerBridge(sendChatMessage)
	initFlowTrigger(sendAction)

	useEffect(() => {
		if (_conversationItems) {
			setConversationItems(_conversationItems)
		}

		if (user) {
			initGetAppTokenBridge(sendChatMessage, user.uid)

			// Send the device token to Firebase
			if (appMode === 'app' && window.invokeXamarinFormsAction) window.invokeXamarinFormsAction('TOKEN')

			if (_conversationItems?.length === 0) {
				sendAction({
					type: 'actionResponse',
					payload: {
						type: 'welcome',
					},
				})
			}
		}
	}, [_conversationItems, user])

	useEffect(() => {
		if (_profile && JSON.stringify(profileData) !== JSON.stringify(_profile)) {
			setProfileData(_profile)
		}
	}, [_profile])

	return (
		<AppCtx.Provider value={appContext}>
			<Helmet>
				<html lang={language} />
			</Helmet>
			<App content={content} history={history} routes={routes} />
		</AppCtx.Provider>
	)
}

const SplashScreenWrapper = () => {
	return (
		<div data-restrain="max">
			<SplashScreen language={language} />
		</div>
	)
}
