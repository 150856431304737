import React, { useState, useRef, useContext, useEffect } from 'react'
import { For } from 'react-loops'
import AppCtx from 'contexts/App'
import s from './Conversation.module.scss'

// Components
import ChatMessage from 'components/site/ChatMessage/ChatMessage'
import ChatInput from 'components/site/ChatInput/ChatInput'
import TypingIndicator from 'components/site/TypingIndicator/TypingIndicator'
import Overlay from 'components/generic/Overlay/Overlay'
import Markdown from 'markdown-to-jsx'
import md2jsxOptions from 'config/md2jsx'

//
export default function Conversation() {
	const { content, conversationItems } = useContext(AppCtx)
	const [isAwaitingResponse, setIsAwaitingResponse] = useState(false)
	const bottomRef = useRef()

	// Filter out actions from conversation items
	const filteredConversationItems = conversationItems?.filter((item) => item.responseType !== 'action')

	// Determine if we're waiting for a response
	useEffect(() => {
		setIsAwaitingResponse(conversationItems[conversationItems.length - 1]?.from === 'user')
		bottomRef.current.scrollIntoView({ behavior: 'smooth' })
	}, [conversationItems])

	//
	useEffect(() => {
		setShowFirstTimeOverlay(!window.localStorage.getItem('firstTimeOverlaySeen'))
		setTimeout(() => {
			setShowFirstTimeOverlayDelay(!window.localStorage.getItem('firstTimeOverlaySeen'))
		}, 400)
	}, [])

	const [showFirstTimeOverlay, setShowFirstTimeOverlay] = useState(false)
	const [showFirstTimeOverlayDelay, setShowFirstTimeOverlayDelay] = useState(false)

	const hideFirstTimeOverlay = () => {
		window.localStorage.setItem('firstTimeOverlaySeen', 'true')
		setShowFirstTimeOverlay(false)

		setTimeout(() => {
			setShowFirstTimeOverlayDelay(false)
		}, 400)
	}
	return (
		<>
			{showFirstTimeOverlayDelay && (
				<Overlay isOpen={showFirstTimeOverlay} onClose={hideFirstTimeOverlay} lockViewport={false} theme="carnation">
					<Markdown options={md2jsxOptions}>{content.firstTimeOverlay.message}</Markdown>
				</Overlay>
			)}

			<article className={s.root} data-restrain="m">
				<p className={s.welcome}>{content.welcomeScreen.title}</p>
				<section className={s.messages} data-gap="s">
					<For of={filteredConversationItems} as={(item) => <ChatMessage {...item} />} />
					{isAwaitingResponse && (
						<div className={s.typingIndicator}>
							<TypingIndicator />
						</div>
					)}
				</section>
				<section className={s.inputBar}>
					<ChatInput />
				</section>
				<span ref={bottomRef}></span>
			</article>
		</>
	)
}
