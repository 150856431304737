import React, { useEffect, useRef, useContext, useState } from 'react'
import ReactDOM from 'react-dom'
import AppCtx from 'contexts/App'
import findUniqueSubtrees from 'utils/findUniqueSubtrees'
import s from './Overlay.module.scss'

// Components
import Icon from 'components/generic/Icon/Icon'
import Button from 'components/generic/Button/Button'

// Hooks
import useOuterClickNotifier from 'hooks/useOuterClickNotifier'

// Assets
import { ReactComponent as BotAvatar } from 'media/bot-avatar--white.svg'

//
export default function Overlay({ id, className = '', theme = 'sinbad', isOpen, onClose = () => {}, lockViewport = true, children, ...props }) {
	const $root = useRef()
	const $inner = useRef()
	const $close = useRef()
	const [$$inert, set$$inert] = useState([])

	const {
		content: {
			general: { closeLabel },
		},
	} = useContext(AppCtx)

	const onKeyUp = (event) => event.key === 'Escape' && isOpen && onClose(event)

	useEffect(() => {
		if (isOpen) {
			setTimeout(() => {
				document.documentElement.dataset.overlayActive = true
				document.documentElement.dataset.lockViewport = lockViewport
			}, 500)
		} else {
			document.documentElement.dataset.overlayActive = false
			document.documentElement.dataset.lockViewport = false
		}
	}, [isOpen, lockViewport])

	useEffect(() => {
		isOpen && $close.current.querySelector('button').focus()
	}, [isOpen])

	useEffect(() => {
		if (isOpen) {
			const _$$inert = findUniqueSubtrees($root.current)
			set$$inert(_$$inert)
			_$$inert.forEach(($el) => ($el.inert = true))
		} else {
			$$inert.forEach(($el) => $el.removeAttribute('inert'))
			set$$inert([])
		}
	}, [isOpen]) // eslint-disable-line

	useOuterClickNotifier((event) => {
		isOpen && onClose(event)
	}, $inner)

	return ReactDOM.createPortal(
		<div ref={$root} id={id} className={`${s.root} ${className}`} data-theme={theme} aria-hidden={!isOpen} onKeyUp={onKeyUp} {...props}>
			<div ref={$inner} className={s.inner}>
				<header className={s.header} ref={$close}>
					<Button className={s.close} kind="plain" onClick={onClose} size="large" iconOnly={true} data-animate="icon-left">
						<span className="visually-hidden">{closeLabel}</span>
						<Icon className={s.icon} name="arrow-back" />
					</Button>
					<BotAvatar className={s.avatar} />
				</header>
				<div className={s.main}>{children}</div>
			</div>
		</div>,
		document.body,
	)
}
