import { getMessageRef } from 'config/firebase'

// Hooks
import { useCollectionData } from 'react-firebase-hooks/firestore'

/**
 * Handle the conversation logic
 */
export default (user) => {
	let query = null

	if (user) {
		query = getMessageRef(user.uid)
			.orderBy('createdAt', 'asc')
			.limitToLast(50)
	}

	const [messages] = useCollectionData(query, { idField: 'id' })

	return messages
}
