import React, { useState, useEffect, useRef } from 'react'
import { For } from 'react-loops'

// Defs
const INTERSECTION_THRESHOLD = 0.7

//
export default function Carousel({ startIndex = 0, className = '', children, ...props }) {
	const $root = useRef()
	const observer = useRef()
	const length = children.length
	const [currentIndex, setCurrentIndex] = useState(startIndex)

	const onPrev = (event) => setCurrentIndex((currentIndex - 1 + length) % length)
	const onNext = (event) => setCurrentIndex((currentIndex + 1) % length)

	const observe = () => [...$root.current.children].forEach(($target) => observer?.current?.observe($target))

	// Scroll to startIndex
	useEffect(() => {
		startIndex != null && [...$root?.current?.children][startIndex].scrollIntoView({ behavior: 'instant', block: 'nearest', inline: 'start' })
	}, [startIndex])

	// Insersection observer for setting the active card on swipe
	useEffect(() => {
		if (!window.IntersectionObserver) return

		const handleIntersection = (entries) => {
			entries = entries.filter((entry) => entry.intersectionRatio >= INTERSECTION_THRESHOLD)
			if (!entries.length) return
			const $target = entries[0].target
			const index = [...$root.current.children].indexOf($target)
			setCurrentIndex(index)
		}

		observer.current = new IntersectionObserver(handleIntersection, { root: $root.current, threshold: INTERSECTION_THRESHOLD })
		observe()
	}, [])

	return (
		<div ref={$root} className={className} {...props}>
			<For
				of={children}
				as={(child, { index }) => {
					const isActive = index === currentIndex

					return (
						<div data-active={isActive}>
							{React.cloneElement(child, {
								onPrev,
								onNext,
								isActive,
								cardIndex: index + 1,
								cardCount: length,
							})}
						</div>
					)
				}}
			/>
		</div>
	)
}
