import React, { useContext } from 'react'
import { For } from 'react-loops'
import AppCtx from 'contexts/App'
import s from './ChatButtonGroup.module.scss'

// Components
import Button from 'components/generic/Button/Button'

//
export default function ButtonGroup({ messageId, type = 'default', buttons, persistentSelection, selectedIndex, className = '' }) {
	const { sendAction } = useContext(AppCtx)
	const size = type === 'entry' ? 'large' : undefined

	return (
		<div className={`${s.root} ${className}`} data-type={type}>
			<For
				of={buttons}
				as={({ label, action, kind }, { index }) => {
					const type = action?.payload?.type
					const value = action?.payload?.value

					const href = type === 'link' ? value : undefined
					const target = type === 'link' ? '_blank' : undefined
					const onClick = type !== 'link' && !(persistentSelection && selectedIndex) ? (e) => sendAction(action, messageId) : undefined
					const disabled = persistentSelection && selectedIndex != null && selectedIndex !== index

					return (
						<div>
							<Button kind={kind} href={href} onClick={onClick} target={target} size={size} disabled={disabled}>
								{label}
							</Button>
						</div>
					)
				}}
			/>
		</div>
	)
}
