/**
 * Helper to manage retrieval and persistence of global parameters
 * @param {String} key - Param name
 * @param {Boolean} [cullUri=true] - Whether to cull the param from the URI
 * @param {Object|null} [persistObj=window.localStorage] - A persistence layer; can provide your own layer that conforms to `localStorage`/`sessionStorage`'s APIs
 */
export default (key, cullUri = true, persistObj = window.localStorage) => {
	const url = new URL(window.location)
	const qs = new URLSearchParams(url.search)
	const value = qs.get(key) || (persistObj ? persistObj.getItem(key) : '')

	value && persistObj && persistObj.setItem(key, value)

	if (cullUri) {
		qs.delete(key)
		url.search = qs
		window.history.replaceState({}, null, url)
	}

	return value
}
