import firebase, { getMessageRef } from 'config/firebase'

/**
 * Get a function to send an action to the bot
 * @param {Object} user - Firebase user object
 * @param {String} appMode - App mode ('web' or 'app')
 * @returns {Function}
 */
export default (user, appMode) => {
	if (!user) {
		return () => {}
	}

	return async (action, messageId) => {
		await getMessageRef(user.uid).add({
			createdAt: firebase.firestore.FieldValue.serverTimestamp(),
			responseType: 'action',
			from: 'user',
			data: {
				text: 'action',
				action: {
					...action,
					messageId: messageId || null,
				},
			},
			userId: user.uid,
			appMode,
		})
	}
}
