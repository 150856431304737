// Mock data
import chatButtonGroup__entry from 'mock-data/chatButtonGroup--entry'
import chatButtonGroup__default from 'mock-data/chatButtonGroup--default'
import foodCard from 'mock-data/foodCard.js'
import infoMessage from 'mock-data/infoMessage.md'

// Assets
import vleeschmeestersFrontUri from 'media/mock/vleeschmeesters-front.jpg'

//
export default [
	{
		responseType: 'buttonGroup',
		from: 'bot',
		data: chatButtonGroup__entry,
	},
	{
		responseType: 'message',
		from: 'user',
		data: {
			from: 'user',
			text: 'Ik wil wel wat eten!',
		},
	},
	{
		responseType: 'message',
		from: 'bot',
		data: {
			from: 'bot',
			text: 'Prima, waar heb je zin in?',
		},
	},
	{
		responseType: 'imageCardCarousel',
		from: 'bot',
		data: {
			cards: [foodCard, foodCard, foodCard, foodCard, foodCard],
		},
	},
	{
		responseType: 'imageCardGroup',
		from: 'bot',
		data: {
			cards: [foodCard, foodCard, foodCard, foodCard],
		},
	},
	{
		responseType: 'buttonGroup',
		from: 'bot',
		data: chatButtonGroup__default,
	},
	{
		responseType: 'imageCard',
		from: 'bot',
		data: foodCard,
	},
	{
		responseType: 'message',
		from: 'bot',
		data: {
			from: 'bot',
			text: 'Lijkt dit je wat?',
		},
	},
	{
		responseType: 'message',
		from: 'user',
		data: {
			from: 'user',
			text: 'Dat ziet er goed uit!',
		},
	},
	{
		responseType: 'message',
		from: 'bot',
		data: {
			from: 'bot',
			text: 'Fijn! Ik ben blij dat ik je heb kunnen helpen. Eet smakelijk bij Pavarotti!',
		},
	},
	{
		responseType: 'message',
		from: 'bot',
		data: {
			from: 'bot',
			text: infoMessage,
		},
	},
	{
		responseType: 'map',
		from: 'bot',
		data: {
			title: 'Kaart naar Slagerij Vleeschmeester',
			location: '3699+9W The Hague',
		},
	},
	{
		responseType: 'message',
		from: 'bot',
		data: {
			from: 'bot',
			text: `Zo ziet de ingang eruit:\n\n![Ingang Slagerij Vleeschmeester](${vleeschmeestersFrontUri})`,
		},
	},
]
