import React from 'react'
import s from './ChatBubble.module.scss'

// Components
import MarkdownChatBubble from 'components/generic/Markdown/ChatBubble/ChatBubble'

//
export default function ChatBubble({ from, text, className = '' }) {
	if (!text) {
		console.error('Tried to render text ChatBubble but there is no text')
		return
	}

	return (
		<article className={`${s.root} ${className}`} data-from={from}>
			<MarkdownChatBubble>{text}</MarkdownChatBubble>
		</article>
	)
}
