import React, { useContext } from 'react'
import AppCtx from 'contexts/App'
import OnboardingCtx from 'contexts/Onboarding'

// Components
import OnboardingLayout from 'components/layouts/Onboarding/Onboarding'
import Button from 'components/generic/Button/Button'

//
export default function Parking() {
	const {
		content: {
			onboarding: { parking: content },
		},
	} = useContext(AppCtx)

	const { formData, setFormData } = useContext(OnboardingCtx)

	const contentComponent = (
		<div>
			<h1>{content.title}</h1>
		</div>
	)

	const footerComponent = (
		<React.Fragment>
			<Button kind="black" type="submit" onClick={() => setFormData({ ...formData, enableParking: 'no' })}>
				{content.noLabel}
			</Button>
			<Button kind="black" type="submit" onClick={() => setFormData({ ...formData, enableParking: 'yes' })}>
				{content.yesLabel}
			</Button>
		</React.Fragment>
	)

	return <OnboardingLayout content={contentComponent} footer={footerComponent} />
}
