import React, { useContext } from 'react'
import AppCtx from 'contexts/App'

//
export default function SaveClientValue({ name, value }) {

    window.localStorage.setItem(name, value)

	return <></>
}
