import React, { useContext, useState } from 'react'
import AppCtx from 'contexts/App'
import QrReader from 'react-qr-scanner'
import s from './ChatInput.module.scss'

// Components
import FormItem from 'components/generic/form/FormItem/FormItem'
import Button from 'components/generic/Button/Button'
import Icon from 'components/generic/Icon/Icon'
import Overlay from 'components/generic/Overlay/Overlay'
import ProfileEditor from 'components/site/ProfileEditor/ProfileEditor'

//
export default function ChatInput() {
	const {
		appMode,
		sendChatMessage,
		content: { navigation: content },
	} = useContext(AppCtx)

	const [chatInputValue, setChatInputValue] = useState('')
	const [isProfileOverlayOpen, setIsProfileOverlayOpen] = useState(false)
	const [isScannerOverlayOpen, setIsScannerOverlayOpen] = useState(false)

	const onSubmit = async (e) => {
		e.preventDefault()

		// Prevent sending empty messages
		if (chatInputValue === '') return

		try {
			await sendChatMessage(chatInputValue)
			setChatInputValue('')
		} catch (e) {
			// @TODO: add error handling here.
			console.log('Could not send message', e)
		}
	}

	const onParkingClick = (event) => {
		sendChatMessage('Ik wil korting met parkeren')
	}

	const onProfileClick = (event) => {
		setIsProfileOverlayOpen(true)
	}

	const onScannerClick = async (event) => {
		if (appMode === 'app' && window.invokeXamarinFormsAction) {
			window.invokeXamarinFormsAction('QR')
		} else {
			try {
				// Get permission ourselves first to prevent the issue (bug) where the first time the front camera is activated even though the rear camera is requested. This seems to work around that.
				const stream = await navigator.mediaDevices.getUserMedia({ video: true, facingMode: 'environment' })
				if (!stream) return
				setIsScannerOverlayOpen(true)
			} catch (e) {}
		}
	}

	const onScan = (result) => {
		if (!result) return
		setIsScannerOverlayOpen(false)
		window.setresult_scanqr(result)
	}

	const onScanError = (error) => {
		// console.warn(error)
	}

	return (
		<div className={s.root} data-restrain="m">
			<form className={s.inner} onSubmit={onSubmit}>
				<FormItem label="" name="chatInput" value={chatInputValue} placeholder={content.inputPlaceholder} className={s.formItem} onChange={(e) => setChatInputValue(e.target.value)} />
				<nav className={s.nav} data-gap="xxxxs">
					{!chatInputValue && (
						<Button kind="sinbad" iconOnly={true} onClick={onParkingClick}>
							<Icon className={s.icon} name="parking" />
							<span className="visually-hidden">{content.parking}</span>
						</Button>
					)}
					{/* TMP: Hide the scanner */}
					{/* {!chatInputValue && (
						<Button kind="apricot-peach" iconOnly={true} onClick={onScannerClick}>
							<Icon className={s.icon} name="scan" />
							<span className="visually-hidden">{content.scanCode}</span>
						</Button>
					)} */}
					{!chatInputValue && (
						<Button kind="carnation" iconOnly={true} onClick={onProfileClick}>
							<Icon className={s.icon} name="profile" />
							<span className="visually-hidden">{content.profile}</span>
						</Button>
					)}
					{chatInputValue && (
						<Button kind="carnation" iconOnly={true} type="submit">
							<Icon className={s.icon} name="send" />
							<span className="visually-hidden">{content.send}</span>
						</Button>
					)}
				</nav>
			</form>
			<Overlay isOpen={isProfileOverlayOpen} onClose={() => setIsProfileOverlayOpen(false)} theme="carnation">
				<ProfileEditor />
			</Overlay>
			<Overlay isOpen={isScannerOverlayOpen} onClose={() => setIsScannerOverlayOpen(false)} theme="apricot-peach">
				{isScannerOverlayOpen && <QrReader facingMode="rear" className={s.scannerOverlay} onScan={onScan} onError={onScanError} />}
			</Overlay>
		</div>
	)
}
