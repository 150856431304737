import firebase, { getMessageRef } from 'config/firebase'

/**
 * Get a function to send a message to the bot
 * @param {Object} user - Firebase user object
 * @param {String} appMode - App mode ('web' or 'app')
 * @returns {Function}
 */
export default (user, appMode) => {
	if (!user) {
		return () => {}
	}

	return async (message) => {
		const messageRef = getMessageRef(user.uid)

		await messageRef.add({
			createdAt: firebase.firestore.FieldValue.serverTimestamp(),
			responseType: 'message',
			from: 'user',
			data: {
				text: message,
				from: 'user',
			},
			userId: user.uid,
			appMode,
		})
	}
}
