import React, { useContext } from 'react'
import AppCtx from 'contexts/App'

// Components
import Error from 'components/site/Error/Error'
import SplashScreen from 'components/site/SplashScreen/SplashScreen'

//
export default class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props)
		this.state = { error: false }

		try {
			// Reset error state upon navigation
			this.historyUnlisten = this.props.history.listen(() => this.setState({ error: false }))
		} catch (e) {}
	}

	componentDidCatch(error, info) {
		// console.log({ error })
		// console.log({ info })
	}

	componentWillUnmount() {
		try {
			this.historyUnlisten()
		} catch (e) {}
	}

	static getDerivedStateFromError(error) {
		return { error: error.message }
	}

	render() {
		if (this.state.error) {
			return this.props.isBootstrap ? <SplashScreenWrapper /> : <ErrorWrapper />
		}

		return this.props.children
	}
}

const ErrorWrapper = () => {
	const {
		content: { genericErrorTitle, genericErrorBody, backToHomeLabel },
	} = useContext(AppCtx)

	return (
		<div data-restrain="max">
			<Error title={genericErrorTitle} body={genericErrorBody} backToHomeLabel={backToHomeLabel} />
		</div>
	)
}

const SplashScreenWrapper = () => {
	const { language } = useContext(AppCtx)

	return (
		<div data-restrain="max">
			<SplashScreen language={language} didError={true} />
		</div>
	)
}
