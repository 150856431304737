import React from 'react'

//
export default React.createContext({
	formData: {
		email: '',
		name: '',
		licensePlate: '',
		enableParking: null,
	},
	setFormData: () => {},
	onFormChange: () => {},
})
