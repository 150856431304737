import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import AppCtx from 'contexts/App'
import history from 'utils/history'

// Components
import Conversation from 'components/site/Conversation/Conversation'

// Hooks
import useBrowserTitle from 'utils/useBrowserTitle'

//
export default function Home() {
	const { appMode, content, user } = useContext(AppCtx)

	if (appMode === 'app' && !user?.email) history.push('/onboarding')

	return (
		<div data-page="Home">
			<Helmet>
				<title>{useBrowserTitle(content.general.homepageBrowserTitle)}</title>
			</Helmet>
			<h1 className="visually-hidden">{content.general.homepageTitle}</h1>
			<Conversation />
		</div>
	)
}
