import React, { useContext } from 'react'
import AppCtx from 'contexts/App'
import OnboardingCtx from 'contexts/Onboarding'

// Components
import OnboardingLayout from 'components/layouts/Onboarding/Onboarding'
import FormItem from 'components/generic/form/FormItem/FormItem'
import Button from 'components/generic/Button/Button'

//
export default function LicensePlate() {
	const {
		content: {
			general: generalContent,
			onboarding: { licensePlate: content },
		},
	} = useContext(AppCtx)

	const { formData, onFormChange } = useContext(OnboardingCtx)

	const contentComponent = (
		<div>
			<h2>{content.title}</h2>
			<FormItem name="licensePlate" value={formData.licensePlate} onChange={onFormChange} required />
		</div>
	)

	const footerComponent = (
		<React.Fragment>
			<Button kind="black" type="reset">
				{generalContent.prevLabel}
			</Button>
			<Button kind="black" type="submit">
				{generalContent.nextLabel}
			</Button>
		</React.Fragment>
	)

	return <OnboardingLayout content={contentComponent} footer={footerComponent} />
}
