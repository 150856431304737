import { useEffect } from 'react'

/**
 * Custom outside-click hook as per <https://stackoverflow.com/a/54292872>
 * @param {Function} onOuterClick - Callback
 * @param {Object} innerRef - React ref
 */
export default (onOuterClick, innerRef) => {
	useEffect(
		() => {
			if (innerRef.current) {
				// Add listener only if element exists
				document.addEventListener('click', handleClick)
				// Unmount previous listener first
				return () => document.removeEventListener('click', handleClick)
			}

			function handleClick(e) {
				innerRef.current && !innerRef.current.contains(e.target) && onOuterClick(e)
			}
		},
		[onOuterClick, innerRef], // Invoke again, if deps have changed
	)
}
