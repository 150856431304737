import { createBrowserHistory } from 'history'
import { wrapHistory } from 'oaf-react-router'

const history = createBrowserHistory()

// Init accessible routing
wrapHistory(history, {
	smoothScroll: true,
})

export default history
