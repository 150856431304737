import React, { useContext } from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import AppCtx from 'contexts/App'
import s from './App.module.scss'

// Components
import SiteHeader from 'components/site/SiteHeader/SiteHeader'
import ErrorBoundary from 'components/containers/ErrorBoundary/ErrorBoundary'

//
export default function App({ content, history, routes }) {
	const { user } = useContext(AppCtx)

	return (
		<div className={s.root}>
			<a href="#main-content" className="visually-hidden">
				{content.skipToMainContentLabel}
			</a>
			<main id="main-content">
				<Router history={history}>
					<ErrorBoundary history={history}>
						{user?.emailVerified && (
							<div data-restrain="max">
								<SiteHeader content={{ siteName: content.siteName }} />
							</div>
						)}
						<ErrorBoundary history={history}>
							<Switch>
								{routes.map(({ path, exact, Component }) => {
									return <Route key={path || ''} path={path} exact={exact} component={Component} />
								})}
							</Switch>
						</ErrorBoundary>
					</ErrorBoundary>
				</Router>
			</main>
		</div>
	)
}
