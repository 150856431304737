import React from 'react'
import s from './Onboarding.module.scss'

// Assets
import { ReactComponent as Avatar } from 'media/bot-avatar--white.svg'
import { ReactComponent as Logotype } from 'media/logotype.svg'

//
export default function OnboardingLayout({ header, content, footer, avatar }) {
	avatar = avatar || <Avatar />

	return (
		<div className={s.root}>
			<div className={s.logotype}>
				<Logotype />
			</div>
			{header && <div className={s.header}>{header}</div>}
			<div className={s.avatar}>{avatar}</div>
			{content && <div className={s.content}>{content}</div>}
			{footer && (
				<div className={s.footer} data-gap="xxxs">
					{footer}
				</div>
			)}
		</div>
	)
}
