/**
 * Find all subtrees in a given scope while avoiding including a given node.
 * The result is a list of nodes that uniquely describes all subtrees in scope while excluding the given node.
 * A callback is also executed for each resultant subtree (not for each node in the subtree).
 * (Initial use case: marking all elements except for an arbitrarily-positioned modal overlay as @inert.)
 * @function
 * @param {Node} targetNode - The subject node, which will be excluded from the result set
 * @param {Function} cb - The callback to call for each resultant node
 * @param {Node} [topNode=document.body] - The node to scope the operation to
 * @param {String[]} [ignoreSelectors=[]] - List of selectors to ignore
 * @returns {Array} - List of found nodes
 */
export default (targetNode, cb, topNode = document.body, ignoreSelectors = []) => {
	/**
	 * @todo: find a better place for the global ignore list. Hardcoded here is not really appropriate, but the functionality needs to exist.
	 */
	ignoreSelectors = ['.PLACEHOLDER_SELECTOR'].concat(ignoreSelectors)

	let excludeNode
	let node = targetNode
	const foundNodes = []

	/* eslint no-loop-func: 0 */
	while ((excludeNode = node) && node !== topNode && (node = node.parentElement)) {
		;[...node.children]
			.filter(node => node !== excludeNode)
			.forEach(node => {
				const ignore = ignoreSelectors.some(sel => node.matches(sel))
				if (!ignore) {
					cb && cb(node)
					foundNodes.push(node)
				}
			})
	}

	return foundNodes
}
