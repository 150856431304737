import { usersRef } from 'config/firebase'

/**
 * Initialize native scanner bridge
 */
export default (sendChatMessage, userId) => {
	// Native QR scanner receiver
	window.setresult_gettokenfirebase = async (value) => {
		try {
			await usersRef.doc(userId).set(
				{
					deviceToken: value,
				},
				{ merge: true },
			)
		} catch (e) {
			console.error(e)
			sendChatMessage(e.message)
		}
	}
}
