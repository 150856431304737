import React, { useContext } from 'react'
import AppCtx from 'contexts/App'
import s from './AppStoreCard.module.scss'

// Components
import { ReactComponent as BotAvatar } from 'media/bot-avatar--white.svg'

// Defs
const APPSTORE_MAP = {
	Android: process.env.REACT_APP_APPSTORE_ANDROID_URL,
	iOS: process.env.REACT_APP_APPSTORE_IOS_URL,
}

//
export default function AppStoreCard() {
	const {
		content: { appStoreCard: content },
		platform,
	} = useContext(AppCtx)

	const url = APPSTORE_MAP[platform]

	const component = (
		<article className={s.root}>
			<div role="presentation" className={s.banner}>
				<BotAvatar className={s.avatar} />
			</div>
			<p className={s.title}>{content.title}</p>
			<p className={s.body}>
				Klik op de link in je email om naar de app te gaan!
				{/* {url && url}
				{!url && content.desktopMessage} */}
			</p>
		</article>
	)

	if (url) {
		return (
			<a className={s.linkWrapper} href={url}>
				{component}
			</a>
		)
	}

	return component
}
