import React from 'react'
import s from './Error.module.scss'

// Components
import Button from 'components/generic/Button/Button'
import Icon from 'components/generic/Icon/Icon'

//
export default function ErrorScreen({ title, body, buttonLabel }) {
	return (
		<article className={s.root}>
			<div className={s.main}>
				<h1>{title}</h1>
				<p>{body}</p>
				<p>
					<Button element="Link" href="/" kind="plain" data-animate="icon-left">
						<Icon name="arrow-back" />
						<span>{buttonLabel}</span>
					</Button>
				</p>
			</div>
		</article>
	)
}
