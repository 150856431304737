export default {
	type: 'default',
	buttons: [
		{
			label: 'Lunch',
			kind: 'apricot-peach',
			action: { type: 'response', value: 'Lunch' },
		},
		{
			label: 'Snack',
			kind: 'royal-blue',
			action: { type: 'response', value: 'Snack' },
		},
		{
			label: 'Diner',
			kind: 'carnation',
			action: { type: 'response', value: 'Diner' },
		},
		{
			label: 'Meenemen',
			kind: 'hawkes-blue',
			action: { type: 'response', value: 'Meenemen' },
		},
	],
}
