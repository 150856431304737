// Components
import * as overrides from 'utils/markdown'

/**
 * Global config for `markdown-to-jsx`
 */
export default {
	forceBlock: true,
	overrides: {
		a: overrides.A,
	},
}
